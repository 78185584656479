.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -14px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 14px; /* gutter size */
  background-clip: padding-box;
  overflow: hidden;
}

/* Style your items */
.my-masonry-grid_column > div > .card { /* change div to reference your elements you put in <Masonry> */
  padding: 0;
  width: 100%;
  object-fit: cover;
  margin: 0 0 14px;
  border: none;
  box-shadow: 0 2px 2px -1px;
}