@import 'custom';


html {
  height: 100vh;
  background: $secondary ;
  background: linear-gradient( to bottom, $primary, #2e76c0) no-repeat center center fixed;
  background-size: cover;
  body {
    height: 100%;
    background-color: transparent;
    #root{
      height: 100%;
      white-space: pre-line;
    }

  }
}