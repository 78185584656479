#home-page{
 > div{
  scroll-margin-top: 100px;
  min-height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
  margin-bottom: 10em;
 }

}